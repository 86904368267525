import { useEffect, useState } from 'react';
import '../../Styles/App.css';
import '../../Styles/Admin.css';

function Admin ()
{
    const [links, setLinks] = useState([]);
    const checkLogin = async () => {
        fetch(`${process.env.REACT_APP_SERVER}/checkLogin`, 
        {
            method: 'GET', 
            credentials: 'include',
            mode: "cors"
        })
        .then(response => response.json())
        .then(responseJSON => {
            if (responseJSON.isLogged)
            {
                getLinks();
                return;
            }
            window.location.href = '/login';
        });
    }
    const move2EditPage = (kod) =>
    {
        window.location.href=`/admin/editLink#${kod}`;
    }
    function download(content, fileName, contentType) {
        const a = document.createElement("a");
        const file = new Blob([content], { type: contentType });
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
      }
    const downloadResults = async (kod) => {
        fetch(`${process.env.REACT_APP_SERVER}/downloadresults?linkcode=${kod}`, 
            {
                method: 'GET', 
                credentials: 'include',
                mode: "cors"
            })
            .then(response => response.json())
            .then(responseJSON => {
                download(JSON.stringify(responseJSON.results), `wyniki-${kod}.json`,"text/json");
            });
    }
    const getLinks = async () => {
        fetch(`${process.env.REACT_APP_SERVER}/getlinks`, 
        {
            method: 'GET', 
            credentials: 'include',
            mode: "cors"
        })
        .then(response => response.json())  
        .then(responseJSON => {
            setLinks(JSON.parse(responseJSON.links));
        });
    }
    const addLink = (url) => {
        window.location.href=url;
    }
    useEffect(() => { checkLogin()}, []);
    return (
        <div className='container'>
            <div className="adminPanel-container">
                <h2>Zarządzanie ankietami</h2>
                <div className="adminPanel-linkListBox">
                {
                    links.map(link => (
                        <div className='adminPanel-linkBox' key={link._id}>
                             <span className='adminPanel-surveyName'>{link.nazwa}</span>
                             <a href={process.env.REACT_APP_CLIENT+"/ankieta#"+link.kod} target="_blank">{process.env.REACT_APP_CLIENT+"/ankieta#"+link.kod}</a>
                             <div className='adminPanel-linkBoxRow'>
                                <span className='adminPanel-numOfCompletions'>Ilość wypełnień: {link.iloscWypelnien}</span>
                                <button onClick={() => move2EditPage(link.kod)} className='adminPanel-linkSettingsButton'>Ustawienia</button>
                                <button onClick={() => downloadResults(link.kod)} className='adminPanel-linkSettingsButton'>Pobierz wyniki</button>
                            </div>
                        </div>
                    ))
                }
                </div>
                <button className='darkButton' onClick={() => addLink('/admin/addnewlink')}>Dodaj ankietę</button>
            </div>
        </div>
    );


}

export default Admin;