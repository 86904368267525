import React from "react";
import { useEffect } from "react";

function Login ()
{
    const checkLogin = async () => {
        fetch(`${process.env.REACT_APP_SERVER}/checkLogin`, 
        {
            method: 'GET', 
            credentials: 'include',
            mode: "cors"
        })
        .then(response => response.json())
        .then(responseJSON => {
            if (responseJSON.isLogged)
            {
                window.location.href = '/admin';
            }
        });
    }
    const login = async () => {
        const loginVal = document.getElementById("loginInput").value;
        const passwordVal = document.getElementById("passwordInput").value;
        fetch(`${process.env.REACT_APP_SERVER}/login`, 
            {
                method: 'POST', 
                credentials: 'include',
                headers: {
                    'Content-Type':'application/json'
                  }, 
                body: JSON.stringify({'login': loginVal, 'password': passwordVal})
            })
            .then(response => response.json())
            .then(responseJSON => {
                checkLogin();
            });
    }
    useEffect(() => { checkLogin()}, []);
    return (
        <div className='container'>
            <div className="adminPanel-container">
                <h1>Logowanie</h1>
                <input id="loginInput" placeholder='Login' type="text" className="textInput" />
                <input id="passwordInput" placeholder='Hasło' type="password" className="textInput" />
                <button onClick={() => login()} className='darkButton'>Zaloguj się</button>
            </div>
        </div>
    );
}

export default Login;