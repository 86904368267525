import React from "react";

class EndSurvey  extends React.Component {
    render () {
        return (
            <div className='container'>
                <div className='survey-endMessageContainer'>
                    <div className="">
                        <span>{this.props.linkEndMessage}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default EndSurvey;