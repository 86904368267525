import React, { useState } from "react";

import '../Styles/Ankieta.css';
import EndSurvey from "./EndSurvey";

function Ankieta () {
    const [surveyId, setSurveyId] = useState(window.localStorage.getItem('surveyId'));
    const [startMessage, setStartMessage] = useState("");
    const [endMessage, setEndMessage] = useState("");
    const getLinkCode = () => {
        if (window.location.hash)
            return window.location.hash.substring(1);
    }
    const saveSurveyId = (surveyId) => {
        window.localStorage.setItem('surveyId', surveyId)
    }
    const startSurvey = async () => {
        const linkCode = getLinkCode();
        const age = document.getElementById("age").value;
        const sex = document.getElementById("sex").value;
        const lateralizacja = document.getElementById("lateralizacja").value;
        if (age == '')
        {
            alert("Proszę wypełnić wszystkie pola.");
            return;
        }
        fetch(`${process.env.REACT_APP_SERVER}/startsurvey`, 
        {
            method: 'POST', 
            headers: {
                'Content-Type':'application/json'
            }, 
            body: JSON.stringify({'linkCode': linkCode, 'age':age , 'sex':sex , 'lateralizacja':lateralizacja })
        })
        .then(response => response.json())
        .then(responseJSON => {
            saveSurveyId(responseJSON.surveyId);
            setSurveyId(responseJSON.surveyId);
        });
    }
    const displayPair = () => {
        fetch(`${process.env.REACT_APP_SERVER}/getpair?surveyId=${surveyId}`, 
        {
            method: 'GET',
        })
        .then(response => response.json())
        .then(responseJSON => {
            if (responseJSON.isDone)
            {
                window.localStorage.removeItem('surveyId');
                setSurveyId(null);
                setEndMessage(responseJSON.linkEndMessage);
                return;
            }
            const imgLeft = document.getElementById("imageLeft");
            const imgRight = document.getElementById("imageRight");
            imgLeft.src = 'data:image/png;base64,'+responseJSON.first.buffer;
            imgRight.src = 'data:image/png;base64,'+responseJSON.second.buffer;
        });
    }
    const sendChoice = (side) => {
        fetch(`${process.env.REACT_APP_SERVER}/setchoice`, 
        {
            method: 'POST', 
            headers: {
                'Content-Type':'application/json'
            }, 
            body: JSON.stringify({
                'surveyId':surveyId,
                'choosen': side
            })
        })
        .then(response => response.json())
        .then(responseJSON => {
            displayPair();
        });
    }
    const getStartMessage = async () => {
        fetch(`${process.env.REACT_APP_SERVER}/getstartmessage`, 
        {
            method: 'POST', 
            headers: {
                'Content-Type':'application/json'
              }, 
            body: JSON.stringify({
                'linkCode': getLinkCode()
            })
        })
        .then(response => response.json())
        .then(responseJSON => {
            setStartMessage(responseJSON.startMessage);
        });
    }
    if (endMessage!="")
    {
        return (
            <EndSurvey linkEndMessage={endMessage} />
        );
    }
    if (surveyId==null)
    {
        getStartMessage();
        return (
            <div className='container'>
                 <div className="survey-startMessageContainer">
                    <h1>Instruction</h1>
                    <span className="survey-startMessage">{startMessage}</span>
                </div>
                <div className="survey-container">
                    <h1>Respondent's data</h1>
                    <span>Age</span>
                    <input style={{width: "150px"}}id="age" placeholder='' min='1'type="number" className="textInput" />
                    <span>Sex</span>
                    <select id="sex">
                        <option value='women'>female</option>
                        <option value='man'>male</option>
                    </select>
                    <span>Lateralization</span>
                    <select id="lateralizacja">
                        <option value='right'>right-handed</option>
                        <option value='left'>left-handed</option>
                    </select>
                
                <button onClick={() => startSurvey()} style={{width: "250px", height: "80px", margin: "20px"}} className="darkButton">Get Started</button>
                </div>
            </div>
        );
    }
    displayPair();
    return (
        <div className='container'>
            <img draggable='false' onClick={() => sendChoice('first')} id="imageLeft" className="survey-compareImage" />
            <img draggable='false' onClick={() => sendChoice('second')} id="imageRight" className="survey-compareImage" />
        </div>
    );
}

export default Ankieta;
