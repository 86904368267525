import React, { useEffect, useState } from "react";
import '../../Styles/App.css';
import '../../Styles/Admin.css';

function EditLink ()
{
    const [images, setImages] = useState([]);
    const loadLink = () => {
        if (window.location.hash)
        {
            const linkCode = window.location.hash.substring(1);
            fetch(`${process.env.REACT_APP_SERVER}/getlink`, 
            {
                method: 'POST', 
                credentials: 'include',
                headers: {
                    'Content-Type':'application/json'
                  }, 
                body: JSON.stringify({linkCode: linkCode})
            })
            .then(response => response.json())
            .then(responseJSON => {
                document.getElementById("linkName").value = responseJSON.data.nazwa;
                document.getElementById("linkCode").value = responseJSON.data.kod;
                document.getElementById("linkStartMessage").value = responseJSON.data.linkStartMessage;
                document.getElementById("linkEndMessage").value = responseJSON.data.linkEndMessage;
                document.getElementById("linkDisplayTime").value = responseJSON.data.displayTime;
                document.getElementById("linkComparisonPicturesNum").value = responseJSON.data.linkComparisonPicturesNum;
                setImages(responseJSON.data.images);
            });
        }
    }
    const updateLink = async () =>
    {
        const linkOldCode = window.location.hash.substring(1);
        const linkName = document.getElementById("linkName").value;
        const linkCode = document.getElementById("linkCode").value;
        const linkStartMessage = document.getElementById("linkStartMessage").value;
        const linkEndMessage = document.getElementById("linkEndMessage").value;
        const linkDisplayTime = document.getElementById("linkDisplayTime").value;
        const linkComparisonPicturesNum = document.getElementById("linkComparisonPicturesNum").value;
        const addLinkObj = 
        {
            'linkOldCode':linkOldCode, 
            'linkName':linkName, 
            'linkCode':linkCode, 
            'linkStartMessage': linkStartMessage,
            'linkEndMessage': linkEndMessage,
            'linkDisplayTime': linkDisplayTime, 
            'linkComparisonPicturesNum': linkComparisonPicturesNum
        };
        fetch(`${process.env.REACT_APP_SERVER}/updatelink`, 
        {
            method: 'POST', 
            credentials: 'include',
            headers: {
                'Content-Type':'application/json'
              }, 
            body: JSON.stringify(addLinkObj)
        })
        .then(response => response.json())
        .then(responseJSON => {
            if (responseJSON.errorCode=='0')
            {
                alert("Dane zostały zapisane.")
                return;
            }
            alert(responseJSON.errorMsg);
        });
    }
    const removeImage = async (imageId) => {
        const linkCode = window.location.hash.substring(1);
        const removeImgObj = {linkCode: linkCode, imageId: imageId}
        fetch(`${process.env.REACT_APP_SERVER}/removeImage`, 
        {
            method: 'POST', 
            credentials: 'include',
            headers: {
                'Content-Type':'application/json'
              }, 
            body: JSON.stringify(removeImgObj)
        }).then(response => response.json())
        .then(responseJSON => {
            if (responseJSON.errorCode=='0')
            {
                loadLink();
                return;
            }
            alert(responseJSON.errorMsg);
        });
    }
    const sendNewImages = async (files) => {
        const formData = new FormData();
        const linkCode = window.location.hash.substring(1);
        formData.append('linkCode', linkCode);
        for (let i = 0; i < files.length; i++)
        {
            formData.append('images', files[i]);
        }
        fetch(`${process.env.REACT_APP_SERVER}/uploadImages`, 
            {
                method: 'POST',
                credentials: 'include', 
                body: formData
            })
            .then(response => response.json())
            .then(responseJSON => {
                if (responseJSON.errorCode == 0)
                {
                    alert("Zdjęcia zostały dodane poprawnie.");
                    document.getElementById("addNewImage").value='';
                    loadLink();
                    return;
                }
                alert(responseJSON.errorMsg); 
            });
    }
    const addNewImages = async () => {
        const files = document.getElementById("addNewImage").files;
        if (files.length == 0)
         {
            alert("Brak wybranych zdjęć.");
            return;
         }
        sendNewImages(files);
    }
    useEffect(() => {loadLink()}, []);
    return (
        <div className='container'>
            <div className="adminPanel-container">
                <h1>Ustawienia ankiety</h1>
                <span>Nazwa ankiety</span>
                <input id="linkName" type="text" className="textInput" />
                <span>Kod ankiety (bez spacji i znaku '#'')</span>
                <input id="linkCode" type="text" className="textInput" />
                <span>Wiadomość startowa</span>
                <input id="linkStartMessage" type="text" className="textInput" />
                <span>Wiadomość końcowa</span>
                <input id="linkEndMessage" type="text" className="textInput" />
                <span>Czas wyświetlania zdjęcia</span>
                <select id="linkDisplayTime">
                    <option value='-1' defaultValue>Until key pressed</option>
                    <option value='1'>1 s</option>
                    <option value='2'>2 s</option>
                    <option value='3'>3 s</option>
                    <option value='5'>5 s</option>
                    <option value='8'>8 s</option>
                    <option value='10'>10 s</option>
                    <option value='15'>15 s</option>
                    <option value='20'>20 s</option>
                    <option value='25'>25 s</option>
                    <option value='30'>30 s</option>
                    <option value='60'>1 m</option>
                </select>
                <span>Ilość zdjęć do porównania:</span>
                <select id="linkComparisonPicturesNum">
                    <option value='32' defaultValue>32</option>
                    <option value='64'>64</option>
                    <option value='128'>128</option>
                    <option value='256'>256</option>
                    <option value='512'>512</option>
                </select>
                <button onClick={() => updateLink()} className='darkButton'>Zapisz</button>
            </div>
            <div className="adminPanel-container">
                <h1>Ustawienia zdjęć</h1>
                <span>Dodaj nowe</span>
                <input id="addNewImage" type="file" multiple />
                <button onClick={() => addNewImages()} className='darkButton'>Dodaj</button>
                <span>Liczba zdjęć: {images.length}</span>
                <div className='adminPanel-imagesBox'>
                    {
                        images.map(image => (
                            <div className='adminPanel-imagesRow' key={image.id}>
                                <span style={{fontWeight: "400"}}>{image.id}</span>
                                <button onClick={() => {removeImage(image.id)}}className='removeImageButton'>Usuń</button>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default EditLink;