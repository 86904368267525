import React from "react";
import '../../Styles/App.css';
import '../../Styles/Admin.css';

function AddNewLink ()
{
    const addLink = async () =>
    {
        const linkName = document.getElementById("linkName").value;
        const linkCode = document.getElementById("linkCode").value;
        const linkStartMessage = document.getElementById("linkStartMessage").value;
        const linkDisplayTime = document.getElementById("linkDisplayTime").value;
        const linkComparisonPicturesNum = document.getElementById("linkComparisonPicturesNum").value;
        const addLinkObj = 
        {
            'linkName':linkName, 
            'linkCode':linkCode, 
            'linkStartMessage': linkStartMessage,
            'linkDisplayTime': linkDisplayTime, 
            'linkComparisonPicturesNum':linkComparisonPicturesNum
        };
        fetch(`${process.env.REACT_APP_SERVER}/addlink`, 
        {
            method: 'POST', 
            credentials: 'include',
            headers: {
                'Content-Type':'application/json'
              }, 
            body: JSON.stringify(addLinkObj)
        })
        .then(response => response.json())
        .then(responseJSON => {
            if (responseJSON.errorCode=='0')
            {
                window.location.href = document.referrer;
                return;
            }
            alert(responseJSON.errorMsg);
        });
    }
    return (
        <div className='container'>
            <div className="adminPanel-container">
                <h1>Dodaj ankietę</h1>
                <input id="linkName" type="text" placeholder="Nazwa ankiety" className="textInput" />
                <input id="linkCode" type="text" placeholder="Kod ankiety bez spacji i znaku '#'" className="textInput" />
                <input id="linkStartMessage" placeholder='Wiadomość startowa' type="text" className="textInput" />
                <span>Czas wyświetlania zdjęcia</span>
                <select id="linkDisplayTime">
                    <option value='-1'>Until key pressed</option>
                    <option value='1'>1 s</option>
                    <option value='2'>2 s</option>
                    <option value='3'>3 s</option>
                    <option value='5'>5 s</option>
                    <option value='8'>8 s</option>
                    <option value='10'>10 s</option>
                    <option value='15'>15 s</option>
                    <option value='20'>20 s</option>
                    <option value='25'>25 s</option>
                    <option value='30'>30 s</option>
                    <option value='60'>1 m</option>
                </select>
                <span>Ilość zdjęć do porównania:</span>
                <select id="linkComparisonPicturesNum">
                    <option value='32' defaultValue>32</option>
                    <option value='64'>64</option>
                    <option value='128'>128</option>
                    <option value='256'>256</option>
                    <option value='512'>512</option>
                </select>
                <button onClick={() => addLink()} className='darkButton'>Dodaj ankietę</button>
            </div>
        </div>
    );
}

export default AddNewLink;