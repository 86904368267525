import {Routes, Route} from 'react-router-dom';
import Ankieta from './pages/Ankieta'
import Admin from './pages/admin/Admin';
import AddNewLink from './pages/admin/AddNewLink';
import EditLink from './pages/admin/EditLink';
import Login from './pages/admin/Login';

function App() {
  return (
    <Routes>
      <Route path='/ankieta' element={<Ankieta />} />
      <Route path='/admin' element={<Admin />} />
      <Route path='/login' element={<Login />} />
      <Route path='/admin/addnewlink' element={<AddNewLink />} />
      <Route path='/admin/editlink' element={<EditLink />} />
    </Routes>
  );
}

export default App;
